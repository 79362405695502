import {
  DAYTIME_SERVICE_TYPE_IDS,
  OVERNIGHT_SERVICE_TYPE_IDS,
  SERVICE_TYPES,
  SERVICE_TYPES_SUPPORTING_RWB,
} from '~/common/constants/app';
import {
  SEARCH_ALGORITHM_QUERY_PARAMETER,
  SEARCH_POSITION_QUERY_PARAMETER,
} from '~/common/constants/search';
import { getServiceTypeIdFromSearchFilters } from '~/common/utils/search';
import {
  addDistinctIdFromCookieAsGetParameterOnBrowserLevel,
  BOOKING_TYPE_MARKETPLACE,
  BOOKING_TYPE_RWB,
  getPetTypesFromSearchFilters,
  mapSearchFiltersToUrlQueryParameters,
} from '~/components/utils/search';
import { detectCrawler } from '~/utils/userAgent';

import type { Item, SearchParameters, ServiceType } from '@madpaws/design-system';
import type { ServiceTypeDetails } from '~/common/constants/app';
import type { LatestReview, SearchFilters, SearchResults } from '~/common/types/search';

export type SitterPrice = {
  id: number;
  price: number;
};

export const getServiceDetailsForSearch = (searchFilters: SearchFilters): ServiceTypeDetails => {
  const {
    service: { type: serviceSlug },
  } = searchFilters;

  const serviceType = SERVICE_TYPES.find((service) => service.slug === serviceSlug);

  return serviceType || SERVICE_TYPES[0];
};

export const hasOnlyOnePet = (searchFilters: SearchFilters): boolean => {
  const { petTypes } = searchFilters;

  return Object.values(petTypes).reduce((accum, numOfPets) => accum + Number(numOfPets), 0) === 1;
};

export const hasDatesForGivenService = (
  searchFilters: SearchFilters,
  serviceTypeId: number
): boolean => {
  const {
    chronology: { startDate, endDate, scheduledDates, rwbStartDate, weekDays },
    bookingType,
  } = searchFilters;

  const isOvernightDatesDefined = !!startDate || !!endDate;

  if (isOvernightDatesDefined && OVERNIGHT_SERVICE_TYPE_IDS.includes(serviceTypeId)) {
    return true;
  }

  if (
    scheduledDates &&
    scheduledDates.length > 0 &&
    bookingType === BOOKING_TYPE_MARKETPLACE &&
    DAYTIME_SERVICE_TYPE_IDS.includes(serviceTypeId)
  ) {
    return true;
  }

  const isRwbDatesDefined = !!rwbStartDate && Object.values(weekDays).some((weekDay) => weekDay);

  if (
    isRwbDatesDefined &&
    bookingType === BOOKING_TYPE_RWB &&
    SERVICE_TYPES_SUPPORTING_RWB.includes(serviceTypeId)
  ) {
    return true;
  }

  return false;
};

export const shouldShowPriceFromPreview = (
  searchFilters: SearchFilters,
  serviceTypeId: number
): boolean => hasDatesForGivenService(searchFilters, serviceTypeId) && hasOnlyOnePet(searchFilters);

const generateSitterProfileUrl = (
  profileUrl: string,
  searchFilters: SearchFilters,
  searchVersion: string | undefined,
  searchPosition: number
): string => {
  let url = `${profileUrl}?${mapSearchFiltersToUrlQueryParameters(
    searchFilters
  )}&${SEARCH_POSITION_QUERY_PARAMETER}=${searchPosition}`;

  if (searchVersion) {
    url += `&${SEARCH_ALGORITHM_QUERY_PARAMETER}=${searchVersion}`;
  }

  return url;
};

// NOTE: exported for unit testing purposes only
export const getSitterPrice = (
  sitterId: number,
  sitterPrices: SitterPrice[],
  basePrice: number,
  showPriceFromPreview: boolean
): number | undefined => {
  if (!showPriceFromPreview) {
    return basePrice;
  }

  const sitterPrice = sitterPrices.find((price) => price.id === sitterId);

  if (!sitterPrice) {
    // return 'undefined' instead of price value to trigger additional request
    // from Design System components on their render
    return undefined;
  }

  return sitterPrice.price;
};

export const defaultImage =
  'https://res.cloudinary.com/madpaws/image/upload/c_fill,w_80/v1/img/no-image.png.jpg';

export const getSearchResultsItems = (
  searchResults: SearchResults,
  searchFilters: SearchFilters,
  sitterPrices: SitterPrice[],
  showlPriceFromPreview: boolean,
  showLatestReview: boolean
): Item[] => {
  const {
    results,
    metaInfo: { searchVersion },
    pageInfo: { offset },
  } = searchResults;

  return results.map(
    (
      {
        badges,
        id,
        location,
        name,
        profilePhoto,
        profileUrl,
        rating,
        seasonalUpsellBanner,
        tagline,
        basePrice,
        latestReview,
      },
      key
    ) => {
      // sitter's position in the search results list
      // based on pagination
      const searchPosition = offset + (key + 1);

      const getLatestReview = (): LatestReview | undefined => {
        if (!latestReview || !showLatestReview) {
          return undefined;
        }

        const { avatar, ...restLatestReview } = latestReview;

        return {
          avatar: avatar || defaultImage,
          ...restLatestReview,
        };
      };

      return {
        badges,
        id,
        location,
        name,
        price: getSitterPrice(id, sitterPrices, basePrice, showlPriceFromPreview) as number,
        profilePhoto,
        profileUrl: generateSitterProfileUrl(
          profileUrl,
          searchFilters,
          searchVersion,
          searchPosition
        ),
        rating,
        seasonalUpsellBanner,
        tagline,
        latestReview: getLatestReview(),
      };
    }
  );
};

export const getSearchResultsParameters = (
  searchFilters: SearchFilters,
  serviceDetails: ServiceTypeDetails
): SearchParameters => {
  const {
    location: {
      address: { suburb },
    },
  } = searchFilters;

  return {
    serviceType: serviceDetails.name as ServiceType,
    serviceTypeTimeUnit: serviceDetails.timeUnit,
    suburb,
  };
};

type SearchResultPriceQueryVariables = {
  bookingType: string;
  endDate?: string;
  petTypes: number[];
  scheduledDates?: string[];
  serviceTypeId: number;
  sitterId: number;
  startDate?: string;
  startDateOfWeek?: string;
  weekDays: boolean[];
};

export const preparePriceQueryVariables = (
  id: number,
  searchFilters: SearchFilters
): SearchResultPriceQueryVariables => {
  const { bookingType } = searchFilters;
  const { startDate, endDate, scheduledDates, rwbStartDate, weekDays } = searchFilters.chronology;
  const { service } = searchFilters;
  const serviceTypeId = getServiceTypeIdFromSearchFilters(service);
  const { petTypes: pets } = searchFilters;
  const petTypesString = getPetTypesFromSearchFilters(pets);
  const petTypes = petTypesString.split(',').map((val) => Number(val));
  const weekdays = Object.values(weekDays).map((val) => Boolean(val));
  return {
    bookingType,
    endDate,
    petTypes,
    scheduledDates,
    serviceTypeId,
    sitterId: id,
    startDate,
    startDateOfWeek: rwbStartDate,
    weekDays: weekdays,
  };
};

export const getPageHrefTemplate = (pageSlug: string): string => {
  const transformedPageSlug = pageSlug
    .replace(/\?page=([0-9]+)&/g, '?')
    .replace(/[?,&]page=([0-9]+)/g, '');

  const separator = transformedPageSlug.includes('?') ? '&' : '?';
  const url = `${transformedPageSlug}${separator}page=:page`;

  return addDistinctIdFromCookieAsGetParameterOnBrowserLevel(url);
};

export const detectCrawlerBeforeEventTrigger = (callbackFunction: () => void): void => {
  const isBot = detectCrawler(navigator.userAgent);

  if (isBot) {
    return;
  }

  callbackFunction();
};
