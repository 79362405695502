import { Button, Dialog, Supplementary } from '@madpaws/design-system';
import { useState } from 'react';

import { trackEvent } from '~/components/analytics/analytics';
import { VIEWED_ADDITIONAL_RATES_EVENT_NAME } from '~/components/analytics/constants';
import { DOM_CONTAINER_ID } from '~/components/constants';

import styles from './AdditionalRates.module.css';
import { getDefaultEventProperties } from '../../tracking/getDefaultEventProperties';
import { AdditionalRatesDetails } from '../AdditionalRatesDetails/AdditionalRatesDetails';

import type { ReactElement } from 'react';
import type { SitterServicesOffered } from '~/common/types/sitter';

type AdditionalRatesProps = {
  servicesOffered: SitterServicesOffered;
};

export const AdditionalRates = ({ servicesOffered }: AdditionalRatesProps): ReactElement => {
  const { firstName, sitterId, sitterProfileServices } = servicesOffered;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const toggleDialog = (id: number): void => {
    setIsDialogOpen(!isDialogOpen);
    if (isDialogOpen) {
      trackEvent(VIEWED_ADDITIONAL_RATES_EVENT_NAME, {
        ...getDefaultEventProperties(id),
      });
    }
  };

  return (
    <div className={styles.root}>
      <Button
        isFullBleed
        label="View Additional Rates"
        onClick={(): void => toggleDialog(sitterId)}
        variant="secondary"
      />

      <Dialog
        domContainerId={DOM_CONTAINER_ID}
        heading={`${firstName}'s rates`}
        isOpen={isDialogOpen}
        onRequestClose={(): void => toggleDialog(sitterId)}
      >
        <AdditionalRatesDetails sitterProfileServices={sitterProfileServices} />
      </Dialog>
      <div className={styles.supplementaryText}>
        <Supplementary>Extra pets, pick up & drop off</Supplementary>
      </div>
    </div>
  );
};
